import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NotifierService } from "./notifier.service";
import { TranslateService } from "@ngx-translate/core";

import { criticalStatusCodes } from './critical-status-codes';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(
    protected notifierService: NotifierService,
    protected translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          // check for internet connection
          if (!navigator.onLine) {
            this.notifierService.notifyError(
              this.translate.instant("errors.http.offline")
            );
          } else {
            // handle HTTP errors
            const message = this.translate.instant(
              `errors.http.${error.status}`
            );
            console.log(criticalStatusCodes);
            this.notifierService.notifyError(message ? message : error, criticalStatusCodes.includes(error.status));
          }
        }

        // need to rethrow so angular can catch the error
        return throwError(error);
      })
    );
  }
}
