import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import {
  AnalyticsDetails,
  AnalyticsSummary,
  AnalyticsTimeline,
  Category,
  Granularity,
  TimelineFilter
} from 'src/app/shared/models/shared.model';
import { Endpoint } from 'src/app/shared/services/backend.service';
import {
  LoadDataAction,
  successAction
} from 'src/app/shared/state/load.actions';
import { FILTER_UPDATE, FilterUpdate } from './dashboard.actions';

export interface DashboardState {
  summary: AnalyticsSummary;
  timeline: AnalyticsTimeline;
  timelineLoaded: boolean;
  timelineBarSelected: boolean;
  details: AnalyticsDetails;
  detailsLoaded: boolean;
  filter: TimelineFilter;
}

export const initialState: DashboardState = {
  summary: null,
  timeline: null,
  timelineLoaded: true,
  timelineBarSelected: false,
  details: null,
  detailsLoaded: true,
  filter: {
    category: Category.ACTIVE,
    'date-from': moment().subtract(7, 'days').startOf('day').toISOString(),
    'date-to': moment().toISOString(),
    granularity: Granularity.DAY,
  },
};

export function reducer(
  state = initialState,
  action: LoadDataAction
): DashboardState {
  switch (action.type) {
    case successAction(Endpoint.GET_ANALYTICS_SUMMARY).type:
      return { ...state, summary: action.payload as AnalyticsSummary };
    case successAction(Endpoint.GET_ANALYTICS_TIMELINE).type:
      return { ...state, timeline: action.payload as AnalyticsTimeline, timelineLoaded: true };
    case successAction(Endpoint.GET_ANALYTICS_DETAILS).type:
      return { ...state, details: action.payload as AnalyticsDetails, detailsLoaded: true };
    case FILTER_UPDATE:

      let newState = {...state};
      let timelineBarSelected = (action as unknown as FilterUpdate).timelineBarSelected;
      if ((action as unknown as FilterUpdate).updateFilter) {
        const newFilter = { ...state.filter, ...action.payload as TimelineFilter };
        newFilter['date-from'] = moment(newFilter['date-from']).utc().startOf('day').toISOString();
        newFilter['date-to'] = moment(newFilter['date-to']).utc().endOf('day').toISOString();
        newState = {...newState, filter:  newFilter, timelineLoaded: false, detailsLoaded: false, timelineBarSelected};
      } else {
        newState = {...newState, detailsLoaded: false, timelineBarSelected};
      }

      return newState;

    default:
      return state;
  }
}

export const selectDashboard = createFeatureSelector<DashboardState>(
  'dashboard'
);
export const selectDetails = createSelector(
  selectDashboard,
  state => state.details
);
export const selectSummary = createSelector(
  selectDashboard,
  state => state.summary
);
export const selectTimeline = createSelector(
  selectDashboard,
  state => state.timeline
);

export const selectIsDataLoaded = createSelector(
  selectDashboard,
  state => state.summary != null
);

export const isTimelineLoading = createSelector(
  selectDashboard,
  state => state.timelineLoaded
);

export const isDetailsLoading = createSelector(
  selectDashboard,
  state => state.detailsLoaded
);

export const isTimelineBarSelected = createSelector(
  selectDashboard,
  state => state.timelineBarSelected
);

export const selectFilter = createSelector(
  selectDashboard,
  state => {
    return { ...state.filter, 'date-from': moment(state.filter['date-from']), 'date-to': moment(state.filter['date-to']) };
  }
);
