export const criticalStatusCodes = [
  0,
  400,
  405,
  404,
  406,
  409,
  500,
  505,
  503,
  507,
  508
];
