import { Category } from 'src/app/shared/models/shared.model';

export const staticSummaryDataImport = {
  [Category.ACTIVE]: {
    titleLang: 'dashboard.details.active-devices',
    icon: 'assets/img/mobile-icons/ACTIVE-DEVICES.svg',
    backgroundColor: 'rgba(2, 167, 240, 1)',
    textColor: 'white',
    clickEvent: true,
    barColor: 'rgba(2, 167, 240, 0.6)',
    subChangePercentText: 'dashboard.details.last.week'
  },
  [Category.NEW_REGISTERED]: {
    titleLang: 'dashboard.details.new-registered-devices',
    icon: 'assets/img/mobile-icons/NEW-REGISTERED-DEVICES.svg',
    backgroundColor: 'rgba(126, 142, 227, 1)',
    textColor: 'white',
    clickEvent: true,
    barColor: 'rgba(126, 142, 227, 0.6)',
    subChangePercentText: 'dashboard.details.last.month'
  },
  [Category.TOTAL_ACTIVE]: {
    titleLang: 'dashboard.details.total-active-devices',
    icon: 'assets/img/mobile-icons/TOTAL-ACTIVE-DEVICES.svg',
    backgroundColor: 'rgba(29, 187, 182, 1)',
    textColor: 'white',
    clickEvent: false,
    barColor: 'rgba(29, 187, 182, 0.6)',
    subChangePercentText: null
  },
  [Category.ABOUT_TO_EXPIRE]: {
    titleLang: 'dashboard.details.about-to-expire-devices',
    icon: 'assets/img/mobile-icons/ABOUT-TO-EXPIRE-DEVICES.svg',
    backgroundColor: 'rgba(253, 185, 88, 1)',
    textColor: 'white',
    clickEvent: false,
    barColor: 'rgba(253, 185, 88, 0.6)',
    subChangePercentText: null
  },
  [Category.EXPIRED]: {
    titleLang: 'dashboard.details.expired-devices',
    icon: 'assets/img/mobile-icons/EXPIRED-DEVICES.svg',
    backgroundColor: 'rgba(235, 104, 110, 1)',
    textColor: 'white',
    clickEvent: true,
    barColor: 'rgba(235, 104, 110, 0.6)',
    subChangePercentText: 'dashboard.details.last.week'
  }
};

export const staticSummaryOrder = [
  Category.ACTIVE,
  Category.NEW_REGISTERED,
  Category.TOTAL_ACTIVE,
  Category.ABOUT_TO_EXPIRE,
  Category.EXPIRED
];
