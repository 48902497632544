import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import {
  AnalyticsSummary, Category, TimelineFilter
} from 'src/app/shared/models/shared.model';
import { StyleService } from 'src/app/shared/services/style.service';
import { ROUTE_TRANSITION } from '../../../../app.animation';
import {
  FilterChange
} from '../../state/dashboard.actions';
import {
  isDetailsLoading, isTimelineLoading, selectFilter,
  selectIsDataLoaded, selectSummary
} from '../../state/dashboard.reducer';
import { staticSummaryDataImport, staticSummaryOrder } from './summaryStatic';

@Component({
  selector: 'elastic-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class DashboardComponent implements OnInit {
  layout: string;

  layoutColumnOnBoxed = 'row';

  summary$: Observable<AnalyticsSummary>;
  summary: AnalyticsSummary;

  filter$: Observable<TimelineFilter>;

  staticSummaryData;
  staticSummaryOrder;
  isLoaded$: Observable<boolean>;

  isTimeLineLoading$: Observable<boolean>;
  isTimeLineLoading = false;
  isTimeLineLoadingTimeout = null;

  isDetailsLoading$: Observable<boolean>;
  isDetailsLoading = false;
  isDetailsLoadingTimeout = null;

  constructor(
    private store: Store<any>,
    public styleService: StyleService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show('home');

    this.staticSummaryData = staticSummaryDataImport;
    this.staticSummaryOrder = staticSummaryOrder;
    this.summary$ = this.store.select(selectSummary);
    this.summary$.subscribe(newSummary => (this.summary = newSummary));
    this.filter$ = this.store.select(selectFilter);

    this.isLoaded$ = this.store.select(selectIsDataLoaded);
    this.isLoaded$.subscribe(loaded => (loaded ? this.spinner.hide('home') : ''));

    this.isTimeLineLoading$ = this.store.select(isTimelineLoading);
    this.isTimeLineLoading$.subscribe(loaded => {
      if (loaded) {
        if (this.isTimeLineLoadingTimeout != null) {
          clearTimeout(this.isTimeLineLoadingTimeout);
        }
        this.isTimeLineLoading = true;
        if (this.isDetailsLoading) this.spinner.hide('dashboard');
      } else {
        this.isTimeLineLoading = false;
        this.isTimeLineLoadingTimeout = setTimeout(() => {
          this.spinner.show('dashboard');
        }, 200);
      }
    });

    this.isDetailsLoading$ = this.store.select(isDetailsLoading);
    this.isDetailsLoading$.subscribe(loaded => {
      if (loaded) {
        if (this.isDetailsLoadingTimeout != null) {
          clearTimeout(this.isDetailsLoadingTimeout);
        }
        this.isDetailsLoading = true;
        if (this.isTimeLineLoading) this.spinner.hide('dashboard');
      } else {
        this.isDetailsLoading = false;
        this.isDetailsLoadingTimeout = setTimeout(() => {
          this.spinner.show('dashboard');
        }, 200);
      }
    });
  }

  onSummaryStateClicked(value, clickEvent) {
    if(clickEvent) {
      const newFilter: TimelineFilter = {
        category: value
      };

      if (value !== Category.ABOUT_TO_EXPIRE) {
        this.store.dispatch(new FilterChange(newFilter));
      }
    }
  }

  filterChanged(filter: TimelineFilter) {
    const dateFrom = moment(filter['date-from']).startOf('day');
    const dateTo = moment(filter['date-to']).endOf('day');
    this.store.dispatch(
      new FilterChange({ ...filter, 'date-from': dateFrom, 'date-to': dateTo })
    );
  }

  ngTrendToIcon(trend: string) {
    switch (trend != null ? trend.toLowerCase() : null) {
      case 'up':
        return 'arrow_upward';
      case 'down':
        return 'arrow_downward';
      case 'equal':
        return 'arrow_forward';
      default:
        return 'arrow_forward';
    }
  }
}
