import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IKeycloakProfile } from '../models/shared.model';
import { selectUser } from '../state/shared.reducer';

@Injectable({
  providedIn: 'root'
})
export class StyleService {

  public standardLayoutGap;

  private user$: Observable<IKeycloakProfile>;
  private userUrl: string;

  constructor(private store: Store<any>) {
    this.standardLayoutGap = window.getComputedStyle(document.documentElement).getPropertyValue('--standardLayoutGap');
    this.user$ = this.store.select(selectUser);

    this.user$.subscribe((userInfo: IKeycloakProfile) => {
      this.userUrl = userInfo.attributes.theme ? userInfo.attributes.theme[0] + '/' : 'assets/';
      this.appendCss();
    });
  }

  appendCss() {
    const lazyStyleElement = document.createElement('link');
    lazyStyleElement.rel = 'stylesheet';
    lazyStyleElement.href = this.userUrl + 'theme.css';
    document.body.appendChild(lazyStyleElement);
  }

  url(name: string) {
    return this.userUrl + name;
  }

}
