import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum Endpoint {
  GET_SERVICE_PROVIDER_APPLICATIONS = '/info',
  GET_ANALYTICS_SUMMARY = '/analytics/devices/summary',
  GET_ANALYTICS_TIMELINE = '/analytics/devices/timeline',
  GET_ANALYTICS_DETAILS = '/analytics/devices/details',
  GET_ATTACKS_SUMMARY = '/analytics/attacks/summary',
  GET_ATTACKS_ALERTS = '/analytics/attacks/alerts',
  GET_ATTACKS_LOGS = '/analytics/attacks/logs',
  EXPORT_ATTACKS_LOGS_TO_CSV = '/analytics/attacks/logs/export-csv'
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(private http: HttpClient) { }

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }
}
