import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { filter, mergeMap, concatMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import {
  loadSummary,
  loadTimeline,
  FILTER_CHANGE,
  FilterUpdate,
  FilterChange,
  loadDetails
} from './dashboard.actions';
import { RouterStateUrl } from 'src/app/reducers/router';

@Injectable()
export class DashboardEffects {
  @Effect()
  redirect$ = this.actions$.pipe(
    filter(action => {
      return action.type === ROUTER_NAVIGATED;
    }),
    filter((action: RouterNavigatedAction<RouterStateUrl>) => {
      return action.payload.routerState.lastSegment === 'endpoints';
    }),
    mergeMap(() => [loadSummary(), loadTimeline(), loadDetails()])
  );

  @Effect()
  filter$ = this.actions$.pipe(
    ofType(FILTER_CHANGE),
    concatMap((action: FilterChange) => {
      const baseActions = [];
      baseActions.push(new FilterUpdate(action.payload, action.updateFilter, action.timelineBarSelected));

      baseActions.push(loadDetails(action.payload));

      if (action.updateTimeline) {
        baseActions.push(loadTimeline());
      }
      return baseActions;
    })
  );

  constructor(private actions$: Actions<Action>) {}
}
