import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Granularity } from '../../models/shared.model';
import { buildConfig } from './barchart-config.data';

@Component({
  selector: 'app-elastic-barchart-widget',
  templateUrl: './barchart-widget.component.html',
  styleUrls: ['./barchart-widget.component.scss']
})
export class BarchartWidgetComponent implements AfterViewInit, OnChanges {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @Input() axis: any[];
  @Input() data: any[];
  @Input() granularity: Granularity;
  @Input() color: string;;

  @Output() selected = new EventEmitter();

  cleanData: [];

  width: number;
  height: number;

  constructor() { }

  arrayColors = [];
  barchartWidgetData: any = {};
  barIndexSelected: number = undefined;
  chart;

  ngOnChanges() {
    if (this.chart) {
      this.chart.destroy();
    }

    if (this.data.length === 0) {
      return;
    }

    this.sortColors();
    this.fCleanData();

    this.barchartWidgetData = {
      labels: this.axis,
      datasets: [
        {
          backgroundColor: this.arrayColors,
          minBarLength: 3,
          borderWidth: 2,
          borderRadius: 10,
          data: this.data
        }
      ]
    };

    if (this.canvas) {
      const ctx = this.canvas.nativeElement.getContext('2d');
      this.chart = new Chart(
        ctx,
        buildConfig(this.barchartWidgetData, this.granularity)
      );
      this.chart.options.onClick = (evt, item) => {
        this.chartClickEvent(item);
      };
    }
  }

  fCleanData() {
    if (this.granularity === Granularity.MONTH) {
      this.axis = this.axis.map((item: Moment) => moment(item).utc().startOf('month'));
    }

    if (this.granularity === Granularity.WEEK) {
      this.axis = this.axis.map((item: Moment) => moment(item).utc().startOf('isoWeek'));
    }

    if (this.granularity === Granularity.DAY) {
      this.axis = this.axis.map((item: Moment) => moment(item).utc().startOf('day'));
    }
  }

  sortColors() {
    let max = Number.MIN_SAFE_INTEGER;
    let min = Number.MAX_SAFE_INTEGER;
    let total = 0;
    let nonZeroItems = 0;

    this.arrayColors = [];

    for (const item of this.data) {
      if (item === 0) {
        continue;
      }

      nonZeroItems++;
      total += item;
      if (item < min) {
        min = item;
      }
      if (item > max) {
        max = item;
      }
    }

    const average = total / nonZeroItems;

    for (const item of this.data) {
      this.arrayColors.push(this.color);
    }
  }

  ngAfterViewInit() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(
      ctx,
      buildConfig(this.barchartWidgetData, this.granularity)
    );
    this.chart.options.onClick = (evt, item) => {
      this.chartClickEvent(item);
    };
  }

  chartClickEvent(item) {
    if (item.length > 0 && this.barchartWidgetData) {
      let selectedIndex: number = item[0]._index;
      if (this.barIndexSelected !== undefined) {
        if (this.barIndexSelected == selectedIndex) {
          this.toggleOpacity(selectedIndex);
          this.barIndexSelected = undefined;
        } else {
          this.toggleOpacity(this.barIndexSelected);
          this.barIndexSelected = selectedIndex;
          this.toggleOpacity(this.barIndexSelected);
        }
      } else {
        this.barIndexSelected = selectedIndex;
        this.toggleOpacity(this.barIndexSelected);
      }
      this.chart.update();
      this.selected.emit(this.barchartWidgetData.labels[selectedIndex]);
    }
  }

  toggleOpacity(index) {
    if (this.arrayColors[index].indexOf(', 1)') !== -1) {
      this.arrayColors[index] = this.arrayColors[index].replace(', 1)', ', 0.6)');
    } else if (this.arrayColors[index].indexOf(', 0.6)') !== -1){
      this.arrayColors[index] = this.arrayColors[index].replace(', 0.6)', ', 1)');
    }
  }
}
