import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js';
import { buildConfig } from './piechart-config.data';

declare function pSBC(p, c0, c1?, l?): string;

@Component({
  selector: 'app-elastic-piechart-widget',
  templateUrl: './piechart-widget.component.html',
  styleUrls: ['./piechart-widget.component.scss']
})
export class PiechartWidgetComponent implements OnChanges, AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() nexttitle: string;
  @Input() axis: string[];
  @Input() data: number[];
  @Input() colors: string[];
  @Input() baseColor = '';
  @Input() type: string;
  @Input() dataHorizontal: [];
  @Input() colorsHorizontal: [];
  @Input() numberOfSegments: number;
  @Input() eventOutput: [];
  @Input() changePercent = '';
  @Input() changeIcon = '';
  @Input() titleClasses = '';
  @Input() axisHorizontal = [];

  @Output() segment: EventEmitter<string> = new EventEmitter<string>();

  maxElementsBeforeOthers = 6;

  chart;
  oldData;

  constructor(
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  piechartWidgetData: any = {};
  total = 0;

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (
      'data' in changes &&
      !this.arraysMatch(changes.data.currentValue, this.oldData) ||
      'title' in changes || 'changePercent' in changes
    ) {

      this.oldData = this.data.slice(0);

      if (this.chart) {
        this.chart.destroy();
      }

      this.total = this.data.reduce((a, b) => a + b, 0);

      if (this.data.length === 0) {
        return;
      }

      if (this.data.length > this.maxElementsBeforeOthers) {
        let others = 0;
        while (this.data.length > this.maxElementsBeforeOthers) {
          others += this.removeSmallest();
        }

        this.data.push(others);
        this.axis.push(this.translate.instant('common.others'));
      }

      if (this.baseColor !== '') {
        this.generateProgressiveColors();
      }

      this.piechartWidgetData = {
        labels: this.axis,
        datasets: [
          {
            backgroundColor: this.colors,
            data: this.data
          }
        ]
      };

      if (this.canvas) {
        const ctx = this.canvas.nativeElement.getContext('2d');
        this.chart = new Chart(
          ctx,
          buildConfig(
            this.canvas,
            this.type,
            this.piechartWidgetData,
            this.segment,
            this.eventOutput
          )
        );
      }
    }
  }

  arraysMatch(arr1, arr2) {

    if (arr1 === undefined || arr2 === undefined) { return false; }
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) { return false; }

    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) { return false; }
    }

    // Otherwise, return true
    return true;
  }

  ngAfterViewInit() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(
      ctx,
      buildConfig(
        this.canvas,
        this.type,
        this.piechartWidgetData,
        this.segment,
        this.eventOutput
      )
    );
  }

  generateProgressiveColors() {
    this.colors = [];
    for (let i = 0; i < this.data.length; i++) {
      let multiplier = -1;
      if (i % 2 === 0) {
        multiplier = 1;
      }
      const newColor = pSBC((i / 10) * multiplier, this.baseColor);
      this.colors.push(newColor);
    }
  }

  removeSmallest() {
    let smallestIndex = -1;
    let minValue = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i] < minValue) {
        minValue = this.data[i];
        smallestIndex = i;
      }
    }

    this.data.splice(smallestIndex, 1);
    this.axis.splice(smallestIndex, 1);

    return minValue;
  }
}
