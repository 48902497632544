import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule, Store } from '@ngrx/store';
import { reducers, metaReducers, State } from './reducers';
import { environment } from '../environments/environment';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer, DefaultRouterStateSerializer
} from '@ngrx/router-store';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { EffectsModule } from '@ngrx/effects';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateService,
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import { KeycloakAngularModule, KeycloakService, KeycloakConfig } from 'keycloak-angular';
import { CustomRouterStateSerializer } from './reducers/router';
import { AppAuthGuard } from './app-auth-guard';
import { AppInit } from './shared/state/shared.actions';
import { SharedEffects } from './shared/state/shared.effects';
import { GlobalErrorHandler } from './error-handling/error-handler';
import { GlobalHttpInterceptor } from './error-handling/http-interceptor';
import { NotifierService } from './error-handling/notifier.service';
import { ErrorComponent } from './error-handling/component/error.component';
import { UtilsModule } from './utils/utils.module';
import { BreadcrumbsModule } from './shared/components/breadcrumbs/breadcrumbs.module';
import { MockKeycloakService } from './mock/mock-keycloak.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export const DEFAULT_LANG = 'en';
export const LANGS = ['en'];

declare function externalJsinit();

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

export function initApplication(
  translateService: TranslateService,
  store: Store<State>,
  keycloak: KeycloakService
) {

  // If we are in a subdomain in production, use that as the realm for keycloak
  const domains = (window.location.hostname.split('.'));

  if (environment.production && domains.length > 1) {
    (environment.keycloakOptions.config as KeycloakConfig).realm = domains[0];
  }

  return (): Promise<any> =>
    new Promise(resolve =>
      keycloak.init(environment.keycloakOptions).then(async () => {
        translateService.setDefaultLang(DEFAULT_LANG);
        translateService.addLangs(LANGS);
        externalJsinit();
        await translateService.use(DEFAULT_LANG).toPromise();
        store.dispatch(new AppInit());
        resolve(null);
      })
    );
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    BreadcrumbsModule,

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([SharedEffects]),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),

    HttpClientModule,
    SharedModule,
    DashboardModule,
    UtilsModule
  ],
  providers: [
    {
      provide: KeycloakService,
      useClass: environment.production ? KeycloakService : MockKeycloakService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [TranslateService, Store, KeycloakService]
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [NotifierService]
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: navigator.language || 'es-ES',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
      deps: [NotifierService, TranslateService]
    },
    AppAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
