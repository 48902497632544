import { Injectable } from '@angular/core';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { IKeycloakProfile } from '../shared/models/shared.model';

@Injectable()
export class MockKeycloakService extends KeycloakService {

    init(_options?: KeycloakOptions): Promise<boolean> {
        return Promise.resolve(true);
    }

    loadUserProfile(_forceReload?: boolean): Promise<IKeycloakProfile> {
        return Promise.resolve({
            username: 'user',
            emailVerified: false,
            attributes: {}
        });
    }

    login(_options?: Keycloak.KeycloakLoginOptions): Promise<void> {
        return Promise.resolve();
    }

    isLoggedIn(): Promise<boolean> {
        return Promise.resolve(true);
    }

    logout(_redirectUri?: string): Promise<void> {
        return Promise.resolve();
    }

    getUserRoles(_allRoles?: boolean): string[] {
        return [];
    }
}