import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

const MAX_DATE_RANGE_VISIBLE = 90;

@Component({
  selector: 'app-custom-time-range-dialog',
  templateUrl: './custom-time-range-dialog.component.html',
  styleUrls: ['./custom-time-range-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTimeRangeDialogComponent {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  private readonly dialogRef: MatDialogRef<CustomTimeRangeDialogComponent>;
  private readonly triggerElementRef: ElementRef;
  private prevFormValues: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateDialogPosition();
  }

  selectedTab = 1;
  timeRangeForm: FormGroup;
  minDate = new Date();
  maxDate = new Date();

  constructor(dialogRef: MatDialogRef<CustomTimeRangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { trigger: ElementRef, prevFilterValues: any }) {
    this.dialogRef = dialogRef;
    this.triggerElementRef = data.trigger;
    this.prevFormValues = data.prevFilterValues;
  }

  ngOnInit() {
    this.selectedTab = this.prevFormValues?.selectedTab ?? 1;
    const today = new Date(Date.now());
    this.minDate.setDate(today.getDate() - MAX_DATE_RANGE_VISIBLE);
    this.maxDate.setDate(today.getDate() + MAX_DATE_RANGE_VISIBLE);

    this.updateDialogPosition();
    this.setForm(this.prevFormValues);
  }

  private updateDialogPosition(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    matDialogConfig.position = { left: `${rect.left - 515}px`, top: `${rect.bottom + 5}px` };
    this.dialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this.dialogRef.updatePosition(matDialogConfig.position);
  }

  private setForm(prevFormValues: any): void {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const tmzOffset = today.getTimezoneOffset() * 60 * 1000;
    const todayWitTmz = new Date(today.getTime() - tmzOffset);
    this.timeRangeForm = new FormGroup({
      fromDate: new FormControl(prevFormValues?.fromDate ?? todayWitTmz),
      toDate: new FormControl(prevFormValues?.toDate ?? todayWitTmz),
      timeValue: new FormControl(prevFormValues?.timeValue ?? 1, Validators.pattern("^[0-9]*$")),
      timeUnit: new FormControl(prevFormValues?.timeUnit ?? 'minutes', Validators.required)
    }, { validators: [this.dateRangeValidator, this.relativeTimeValidator] });
  }

  private dateRangeValidator: ValidatorFn = (): any => {
    let invalid = false;
    const from = this.timeRangeForm?.get('fromDate').value;
    const to = this.timeRangeForm?.get('toDate').value;
    if (from && to) {
      invalid = new Date(from).valueOf() + 1000 * 3600 * 24 * 30 < new Date(to).valueOf()  //checking if date difference is less than 31 days
    }
    return invalid
      ? this.timeRangeForm?.controls['toDate'].setErrors({ invalidRange: true })
      : this.timeRangeForm?.controls['toDate'].setErrors(null);
  };

  private relativeTimeValidator: ValidatorFn = (): { [key: string]: any; } | null => {
    let invalid = false;
    const timeValue = this.timeRangeForm?.get('timeValue').value;
    const timeUnit = this.timeRangeForm?.get('timeUnit').value;
    if (timeValue && timeUnit) {
      const timeMillis = this.getTimeMillisCalculation(timeUnit, timeValue);
      invalid = timeMillis > 31 * 24 * 60 * 60 * 1000;
    }
    return invalid ? { invalidTimeValue: { timeValue } } : null;
  };

  private getTimeMillisCalculation(timeUnit: string, timeValue: number): number {
    switch (timeUnit) {
      case 'minutes':
        return timeValue * 60 * 1000;
      case 'hours':
        return timeValue * 60 * 60 * 1000;
      case 'days':
        return timeValue * 24 * 60 * 60 * 1000;
      case 'weeks':
        return timeValue * 7 * 24 * 60 * 60 * 1000;
      case 'months':
        return timeValue * 30 * 24 * 60 * 60 * 1000;
    }
  }


  apply(): void {
    const values = this.timeRangeForm.value;
    switch (this.tabGroup.selectedIndex) {
      case 0:
        const fromDate = new Date(values.fromDate);
        const toDate = new Date(values.toDate);
        this.dialogRef.close({
          fromDate: new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, -fromDate.getTimezoneOffset(), 0, 0),
          toDate: new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59 - toDate.getTimezoneOffset(), 59, 999),
          formValues: { fromDate: fromDate, toDate: toDate, selectedTab: this.tabGroup.selectedIndex }
        });
        break;
      case 1:
        this.dialogRef.close({
          fromDate: new Date(Date.now() - this.getTimeMillisCalculation(values.timeUnit, values.timeValue)),
          toDate: new Date(),
          formValues: { timeValue: values.timeValue, timeUnit: values.timeUnit, selectedTab: this.tabGroup.selectedIndex }
        });
        break;
      default:
        break;
    }
  }
}
