import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UtilsModule } from '../utils/utils.module';
import { AttacksFilterComponent } from './components/attacks-filter/attacks-filter.component';
import { CustomTimeRangeDialogComponent } from './components/attacks-filter/custom-time-range-dialog/custom-time-range-dialog.component';
import { BarchartWidgetComponent } from './components/barchart-widget/barchart-widget.component';
import { MaxRangeDirective } from './components/filter/date-range-picker/date-range-picker.directive';
import { FilterComponent } from './components/filter/filter.component';
import { GravatarComponent } from './components/gravatar/gravatar.component';
import { HorizontalbarComponent } from './components/horizontalbar/horizontalbar.component';
import { PiechartWidgetComponent } from './components/piechart-widget/piechart-widget.component';
import { ScrollbarModule } from './components/scrollbar/scrollbar.module';
import { StateWidgetComponent } from './components/state/state-widget.component';
import { MaterialModule } from './material.module';
import * as fromShared from './state/shared.reducer';
import { ConfirmationDialog } from './components/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [
    FilterComponent,
    AttacksFilterComponent,
    CustomTimeRangeDialogComponent,
    ConfirmationDialog,
    MaxRangeDirective,
    StateWidgetComponent,
    BarchartWidgetComponent,
    PiechartWidgetComponent,
    HorizontalbarComponent,
    GravatarComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    StoreModule.forFeature('shared', fromShared.reducer),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularResizedEventModule,
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    UtilsModule,
    NgxSpinnerModule,
    MomentDateModule
  ],
  exports: [
    TranslateModule,
    ScrollbarModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FilterComponent,
    AttacksFilterComponent,
    CustomTimeRangeDialogComponent,
    StateWidgetComponent,
    BarchartWidgetComponent,
    PiechartWidgetComponent,
    HorizontalbarComponent,
    GravatarComponent,
    NgxSpinnerModule,
    MomentDateModule
  ]
})
export class SharedModule { }
