<div class="custom-time-range-dialog">
  <mat-dialog-content>
    <mat-tab-group [formGroup]="timeRangeForm" [selectedIndex]="selectedTab">
      <mat-tab label="Absolute">
        <div class="tab-content">
          <mat-form-field class="date-range-picker">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
              <input matStartDate formControlName="fromDate" placeholder="Start date">
              <input matEndDate formControlName="toDate" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="timeRangeForm.controls.toDate.hasError('invalidRange')">
              {{ 'custom-time-range-dialog.errors.invalid-range' | translate }}
            </mat-error>
            <mat-error *ngIf="timeRangeForm.controls.fromDate.hasError('matDatepickerMin') || timeRangeForm.controls.toDate.hasError('matEndDateInvalid') || (timeRangeForm.controls.toDate.hasError('matDatepickerMax') && !timeRangeForm.controls.toDate.hasError('invalidRange'))
            ">
              {{ 'custom-time-range-dialog.errors.invalid-range-limits' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-tab>

      <mat-tab label="Relative">
        <div class="tab-content">
          <mat-form-field appearance="outline" class="time-value-input">
            <input matInput type="number" formControlName="timeValue">
            <mat-error *ngIf="timeRangeForm.controls.timeValue.hasError('pattern')">
              {{ 'custom-time-range-dialog.errors.must-be-integer' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="time-unit-selector" appearance="outline">
            <mat-select formControlName="timeUnit">
              <mat-option value="minutes">Minutes</mat-option>
              <mat-option value="hours">Hours</mat-option>
              <mat-option value="days">Days</mat-option>
              <mat-option value="weeks">Weeks</mat-option>
              <mat-option value="months">Months</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-error class="invalid-time-error" *ngIf="timeRangeForm.hasError('invalidTimeValue')">
          {{ 'custom-time-range-dialog.errors.invalid-time-value' | translate }}
        </mat-error>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="cancel" mat-dialog-close>Cancel</button>
    <button mat-button class="apply" cdkFocusInitial [disabled]="!timeRangeForm.valid" (click)="apply()">APPLY</button>
  </mat-dialog-actions>
</div>