<div class="widget">
  <mat-card>
    <mat-card-header [ngClass]="getTitleBoxClass()">
      <div
      fxLayout="row"
      fxFlex="grow"
      fxLayoutAlign="space-between center"
      >
        <mat-card-title class="title">{{ 'dashboard.timeline.historic' | translate }}</mat-card-title>

        <mat-chip-list *ngIf="tooMuchData">
          <mat-chip
            [matTooltip]="'common.filter.tooMuchDataTooltip' | translate"
            matTooltipPosition="above"
            color="warn"
            selected
          >
            {{ 'common.filter.tooMuchData' | translate }}
          </mat-chip>
        </mat-chip-list>

        <mat-chip-list *ngIf="noData">
          <mat-chip
            [matTooltip]="'common.filter.noDataTooltip' | translate"
            matTooltipPosition="above"
            color="warn"
            selected
          >
            {{ 'common.filter.noData' | translate }}
          </mat-chip>
        </mat-chip-list>

        <div></div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <app-elastic-barchart-widget
        *ngIf="data.length > 0"
        [color]="color"
        [axis]="axis"
        [data]="data"
        [granularity]="granularity"
        (selected)="timeSelected($event)"
      ></app-elastic-barchart-widget>
    </mat-card-content>
  </mat-card>
</div>
