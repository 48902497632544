<div class="horizontalbar" [@myAnimation]="state">
  <div fxLayout="row" fxLayout.lt-md="row"  [matTooltip]="tooltip" matTooltipClass='horizontalbar-tooltip'>
    <div *ngFor="let segment of segments; let i = index" [attr.data-index]="i">
      <div fxLayout="row" fxLayout.lt-md="row">
        <div
          class="segment"
          [ngStyle]="{ 'background-color': segment, width: width }"
        ></div>
        <div
          class="segment"
          [ngStyle]="{ width: smallwidth }"
          style="background-color: white;"
        ></div>
      </div>
    </div>
  </div>
</div>
