import { KeycloakProfile } from 'keycloak-js';
import { Moment } from 'moment';

/** Common types */

export interface Application {
  id: string;
  name: string;
}

export interface ServiceProvider {
  id: string;
  name: string;
  applications: Array<Application>;
}

export enum Trend {
  EQUAL = 'equal',
  UP = 'up',
  DOWN = 'down'
}

export enum Granularity {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}

export enum Category {
  ACTIVE = 'active-devices',
  NEW_REGISTERED = 'new-registered-devices',
  TOTAL_ACTIVE = 'total-active-devices',
  ABOUT_TO_EXPIRE = 'about-to-expire-devices',
  EXPIRED = 'expired-devices'
}

/** Analytics types */

export interface DevicesAmount {
  amount: number; // From 0.0 to 1.0
}

export interface DevicesAnalytics extends DevicesAmount {
  id: number;
}

export interface DevicesTrend {
  trend: Trend;
}

export interface DevicesPercentatge {
  percent: number;
}

export interface DateInterval {
  'date-from': string;
  'date-to': string;
}

export type AllDevicesAnalytics = DevicesAnalytics &
  DevicesTrend &
  DevicesPercentatge &
  DateInterval;

export interface AnalyticsSummary {
  'active-devices': AllDevicesAnalytics;
  'new-registered-devices': AllDevicesAnalytics;
  'total-active-devices': DevicesAnalytics;
  'about-to-expire': DevicesAnalytics & DevicesPercentatge & DevicesTrend;
  'expired-devices': AllDevicesAnalytics;
}

export interface AnalyticsTimeline {
  granularity: Granularity;
  items: Array<AnayliticsTimelineUnit>;
}

export interface AnayliticsTimelineUnit {
  amount: number;
  'date-from': string;
  'date-to': string;
}

export interface IKeycloakProfile extends KeycloakProfile {
  attributes?: any;
}

export interface OsVersionDetails extends DevicesPercentatge, DevicesAmount {
  name: string;
}

export type RootedDeviceDetails = DevicesAmount & DevicesPercentatge;
export type JailbrokenDeviceDetails = DevicesAmount & DevicesPercentatge;

export interface OsDetails extends DevicesAmount, DevicesPercentatge {
  amount: number;
  trend?: number;
  'os-versions': Array<OsVersionDetails>;
}

export interface AnalyticsDetails {
  android: OsDetails & {
    'rooted-devices': RootedDeviceDetails;
  };
  ios: OsDetails & {
    'jailbroken-devices': JailbrokenDeviceDetails;
  };
}

export interface AnalyticsFilter {
  category?: Category;
  'date-from'?: string | Moment;
  'date-to'?: string | Moment;
}

export interface TimelineFilter extends AnalyticsFilter {
  granularity?: Granularity;
}

export enum DeviceOs {
  IOS = 'ios',
  ANDROID = 'android'
}

export interface DetailsFilter extends AnalyticsFilter {
  'device-os'?: DeviceOs;
}

export const OSColors = {
  [DeviceOs.IOS]: [
    '#e57353',
    '#f18f3d',
    '#f6b64e',
    '#ebc54f',
    '#efde7b',
    '#efeca2',
    '#f7f4d7'
  ],
  [DeviceOs.ANDROID]: [
    '#3b694e',
    '#38885d',
    '#46a36d',
    '#65b766',
    '#8cbc6c',
    '#aed399',
    '#cde1b0'
  ]
};