<div id="dashboard" *ngIf="(isLoaded$ | async)">

  <div fxLayout="column" [fxLayoutGap]="styleService.standardLayoutGap">
    <div
      id="summary"
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxFlex="1 1 100%"
      fxFlexFill
    >

      <div
        *ngFor="let key of staticSummaryOrder"
        class="widget"
        fxFlex="1 1 18"
      >
        <elastic-state-widget *ngIf="(filter$ | async).category"
          [property]="staticSummaryData[key].titleLang | translate"
          [value]="summary[key].amount"
          [icon]="staticSummaryData[key].icon"
          [changePercent]="summary[key].percent"
          [changeIcon]="ngTrendToIcon(summary[key].trend)"
          [backgroundColor]="staticSummaryData[key].backgroundColor"
          [textColor]="staticSummaryData[key].textColor"
          [selected]="(filter$ | async).category === key"
          (click)="onSummaryStateClicked(key, staticSummaryData[key].clickEvent)"
          [clicable]="staticSummaryData[key].clickEvent"
          [subChangePercentText]="staticSummaryData[key].subChangePercentText"
        ></elastic-state-widget>
      </div>
    </div>

    <div><mat-divider></mat-divider></div>

    <mat-card *ngIf="filter$ | async as filter" style="margin-bottom:20px">
      <app-filter
            [initialValues]="filter"
            (filterChanged)="filterChanged($event)"
      ></app-filter>
    </mat-card>

    <div class="widget" fxFlex>
      <app-analytics-timeline *ngIf="isTimeLineLoading" ></app-analytics-timeline>
    </div>

    <app-analytics-details *ngIf="isDetailsLoading && isTimeLineLoading" fxFlex></app-analytics-details>

    <div style="position: relative; width: 100%; height: 300px;">
      <ngx-spinner [fullScreen]="false" color="#444" bdColor="rgba(0,0,0,0.0)" name="dashboard"></ngx-spinner>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start start"
      fxLayout.lt-md="start stretch"
    ></div>
  </div>
</div>
