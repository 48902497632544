import { createFeatureSelector } from '@ngrx/store';

export interface HomeState {
}

export const initialState: HomeState = {
}

export function reducer(state = initialState, action): HomeState {
  switch (action.type) {

    default: {
      return state;
    }
  }
}

export const selectHome = createFeatureSelector<HomeState>(
  'home'
);
