import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  concatMap,
  filter,
  withLatestFrom,
  tap,
  mergeMap
} from 'rxjs/operators';
import { of, from, Observable } from 'rxjs';
import {
  LoadDataActionsType,
  LoadData,
  successAction,
  failureAction,
  buildUrl
} from './load.actions';
import { BackendService, Endpoint } from '../services/backend.service';
import { Action, Store } from '@ngrx/store';
import { State } from '../../reducers';
import {
  loadServiceProviders,
  Redirect,
  REDIRECT,
  GET_USER_INFO,
  SaveUserInfo,
  GetUserInfo,
  APP_INIT
} from './shared.actions';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class SharedEffects {
  @Effect()
  loadShareds$ = this.actions$.pipe(
    filter(
      action =>
        action.hasOwnProperty('load') &&
        (action as LoadData).load === LoadDataActionsType.LOAD
    ),
    withLatestFrom(this.store),
    mergeMap(([action, state]: [LoadData, State]) => {
      let params = action.payload.params;

      if (action.payload.stateSelector) {
        params = {
          ...params,
          ...action.payload.stateSelector(state)
        };
      }

      const url = buildUrl(action.payload.url, params);

      return this.backend.get(url).pipe(
        map(data => successAction(Endpoint[action.type], data)),
        catchError(error => of(failureAction(Endpoint[action.type], error)))
      );
    })
  );

  @Effect({ dispatch: false })
  redirect$ = this.actions$.pipe(
    filter(action => action.type === REDIRECT),
    tap(action => {
      this.router.navigate([(action as Redirect).payload.url]);
    })
  );

  @Effect()
  getUserInfo$: Observable<SaveUserInfo> = this.actions$.pipe(
    filter(action => action.type === GET_USER_INFO),
    mergeMap(() =>
      from(this.keycloakService.loadUserProfile()).pipe(
        map(data => new SaveUserInfo(data))
      )
    )
  );

  @Effect()
  $init = this.actions$.pipe(
    ofType(APP_INIT),
    concatMap(() => [new GetUserInfo(), loadServiceProviders()])
  );

  constructor(
    private store: Store<State>,
    private actions$: Actions<Action>,
    private backend: BackendService,
    private router: Router,
    private keycloakService: KeycloakService
  ) {}
}
