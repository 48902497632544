import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from 'src/app/reducers';
import { AnalyticsDetails, DeviceOs, OSColors, TimelineFilter } from '../../../../shared/models/shared.model';
import { isTimelineBarSelected, selectDetails, selectFilter } from '../../state/dashboard.reducer';

declare function pSBC(p, c0, c1?, l?): string;

@Component({
  selector: 'app-analytics-details',
  templateUrl: './analytics-details.component.html',
  styleUrls: ['./analytics-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsDetailsComponent implements OnInit {
  details$: Observable<AnalyticsDetails>;

  timelineFilter: TimelineFilter;

  androidStaticInfo = {
    baseColor: '#75B178',
    lightColor: null,
    title: 'dashboard.details.android',
    rooted: 'dashboard.details.rootedDevices',
    nonRooted: 'dashboard.details.nonRootedDevices',
    colors: OSColors[DeviceOs.ANDROID]
  };

  iosStaticInfo = {
    baseColor: '#EAB93D',
    lightColor: null,
    title: 'dashboard.details.ios',
    rooted: 'dashboard.details.jailbrokenDevices',
    nonRooted: 'dashboard.details.nonJailbrokenDevices',
    colors: OSColors[DeviceOs.IOS]
  };

  selected: DeviceOs = DeviceOs.ANDROID;

  public DeviceOs: any = DeviceOs;
  timelineBarSelected: boolean;

  constructor(
    private store: Store<State>,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    this.androidStaticInfo.lightColor = pSBC(
      0.35,
      this.androidStaticInfo.baseColor
    );
    this.iosStaticInfo.lightColor = pSBC(0.35, this.iosStaticInfo.baseColor);
  }

  ngOnInit() {
    this.details$ = this.store.select(selectDetails);

    this.store.select(selectFilter)
    .subscribe(filter => {
      this.timelineFilter = filter;
    });

    this.store.select(isTimelineBarSelected)
    .subscribe(timelineBarSelected => {
      this.timelineBarSelected = timelineBarSelected;
    });

    this.details$.subscribe(detail => {
      if (!this.changeDetectionRef['destroyed']) {
        this.changeDetectionRef.detectChanges();
      }
      if (detail) {
        this.selected = DeviceOs.ANDROID;
      }
    });
  }

  getAndroidInfo(detail) {
    const androidInfo = {
      versions: [],
      values: [],
      devices: detail.android.amount,
      rootedDevices: detail.android['rooted-devices'].amount,
      trend: detail.android.trend,
      changeIcon: this.getChangeIcon(detail.android.trend)
    };
    detail.android['os-versions'].forEach(item => {
      androidInfo.values.push(item.amount);
      androidInfo.versions.push(item.name);
    });
    return androidInfo;
  }

  getIosInfo(detail) {
    const iosInfo = {
      versions: [],
      values: [],
      devices: detail.ios.amount,
      rootedDevices: detail.ios['jailbroken-devices'].amount,
      trend: detail.ios.trend,
      changeIcon: this.getChangeIcon(detail.ios.trend)
    };
    detail.ios['os-versions'].forEach(item => {
      iosInfo.values.push(item.amount);
      iosInfo.versions.push(item.name);
    });

    return iosInfo;
  }

  getTotalInfo(detail) {
    return {
      devices: detail.ios.amount + detail.android.amount,
      iosAmount: detail.ios.amount,
      iosRooted: detail.ios['jailbroken-devices'].amount,
      androidAmount: detail.android.amount,
      androidRooted: detail.android['rooted-devices'].amount
    };
  }

  getChangeIcon(value) {
    return value >= 0 ? 'trending_up' : 'trending_down';
  }

  getTrend(info: any): string {
    return info.trend ? info.trend + '%' : undefined;
  }

  selectedSegment(value) {
    this.selected = value;
  }

  selectedInfo(detail) {
    if (this.selected === DeviceOs.ANDROID) {
      return this.getAndroidInfo(detail);
    } else if (this.selected === DeviceOs.IOS) {
      return this.getIosInfo(detail);
    }
  }

  selectedStaticInfo() {
    if (this.selected === DeviceOs.ANDROID) {
      return this.androidStaticInfo;
    } else if (this.selected === DeviceOs.IOS) {
      return this.iosStaticInfo;
    }
  }

  getDetailsBoxClasses() {
    if (this.timelineFilter && this.timelineFilter.category) {
      if (this.timelineBarSelected) {
        return this.timelineFilter.category + '-box';
      } else {
        return this.timelineFilter.category + '-box general-box';
      }
    }
    return '';
  }
}
