import { Action } from '@ngrx/store';
import { Endpoint } from '../services/backend.service';
import { State } from '../../reducers';
import { environment } from './../../../environments/environment';

export enum LoadDataActionsType {
  LOAD = 'LOAD',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export class LoadData implements Action {
  load = LoadDataActionsType.LOAD;
  constructor(
    public type: string,
    public payload: {
      url: string;
      params: Dictionary<any>;
      stateSelector?: (state: State) => Dictionary<any>;
    }
  ) {}
}

export class LoadDataSuccess implements Action {
  load = LoadDataActionsType.SUCCESS;
  constructor(public type: string, public payload: any) {}
}

export class LoadDataFailure implements Action {
  load = LoadDataActionsType.FAILURE;
  constructor(public type: string, public payload: { error: any }) {}
}

export type LoadDataAction = LoadData | LoadDataSuccess | LoadDataFailure;

export interface Dictionary<T> {
  [key: string]: T;
}

export function buildUrl(url: string, params: Dictionary<any>): string {
  const paramsString = Object.keys(params)
    .reduce((query, key) => `${query}&${key}=${params[key]}`, '')
    .substring(1);

    if (url.startsWith('/info')) {
      return `${environment.infoBaseUrl}${url}${paramsString ? `?${paramsString}` : ''}`;
    } else {
      return `${environment.apiBaseUrl}${url}${paramsString ? `?${paramsString}` : ''}`;
    }
}

export function getType(endpoint: Endpoint): string {
  let name: string = endpoint;
  for (const endpointName in Endpoint) {
    if (Endpoint[endpointName] === endpoint) {
      name = endpointName;
    }
  }
  return name;
}

export function loadAction(
  endpoint: Endpoint,
  params: Dictionary<any>,
  stateSelector: (state: State) => any = null
): LoadData {
  return new LoadData(getType(endpoint), {
    url: endpoint,
    params,
    stateSelector
  });
}

export function successAction(
  endpoint: Endpoint,
  payload: any = null
): LoadDataSuccess {
  return new LoadDataSuccess(getType(endpoint) + '_SUCCESS', payload);
}

export function failureAction(endpoint: Endpoint, error: any): LoadDataFailure {
  return new LoadDataFailure(getType(endpoint) + '_ERROR', { error });
}
