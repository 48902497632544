import { Params, RouterStateSnapshot } from '@angular/router';
import {
    routerReducer, RouterReducerState,
    RouterStateSerializer
} from '@ngrx/router-store';
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
    lastSegment: string;
}

export interface RouterState extends RouterReducerState<RouterStateUrl> { }

export const serializer = (routerState: RouterStateSnapshot) => {
  let route = routerState.root;
  let params = {};
  let lastSegment = null;

  while (route.firstChild) {
      route = route.firstChild;
      if (route.routeConfig.path !== '') { lastSegment = route.routeConfig.path; }
      params = {...params, ...route.params};
  }

  const { url } = routerState;
  const queryParams = routerState.root.queryParams;

  // Only return an object including the URL, params and query params
  // instead of the entire snapshot
  return { url, params, queryParams, lastSegment};
};


@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        return serializer(routerState);
    }
}

export const reducer = routerReducer;
