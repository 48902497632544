// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { KeycloakConfig, KeycloakOptions } from 'keycloak-angular';

const keycloakConfig: KeycloakConfig = {
  realm: 'insights-portal',
  clientId: 'tak-analytics',
  url: 'https://login.tak-api.com/auth'
};

const keycloakOptions: KeycloakOptions = {
  config: keycloakConfig,
  bearerPrefix: 'Bearer',
  initOptions: {
    onLoad: 'login-required',
    checkLoginIframe: false
  }
};

export const environment = {
  production: true,
  apiBaseUrl: 'https://api-insight-mock.development-eu-north-1.dev.build38.cloud',
  infoBaseUrl: 'https://api-insight-mock.development-eu-north-1.dev.build38.cloud',
  keycloakOptions
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
