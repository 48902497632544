import { Action } from "@ngrx/store";
import { TimelineFilter } from "src/app/shared/models/shared.model";
import { Endpoint } from "src/app/shared/services/backend.service";
import { loadAction } from "src/app/shared/state/load.actions";

export const FILTER_CHANGE = "[Dashboard] Filter changed";
export const FILTER_UPDATE = "[Dashboard] Filter update";

export function loadSummary() {
  return loadAction(Endpoint.GET_ANALYTICS_SUMMARY, {}, state => ({
    "application-id": state.router.state.params.idApplication
  }));
}

export function loadTimeline() {
  return loadAction(Endpoint.GET_ANALYTICS_TIMELINE, {}, state => ({
    ...{
      "application-id": state.router.state.params.idApplication
    },
    ...state.dashboard.filter
  }));
}

export function loadDetails(filter = null) {
  return loadAction(Endpoint.GET_ANALYTICS_DETAILS, {}, state => {
    let new_filter = { ...state.dashboard.filter };

    if (filter) {
      if (filter["date-from"]) {
        new_filter["date-from"] = filter["date-from"].toISOString();
      }

      if (filter["date-to"]) {
        new_filter["date-to"] = filter["date-to"].toISOString();
      }
    }

    return {
      "application-id": state.router.state.params.idApplication,
      ...new_filter
    };
  });
}

export class FilterChange implements Action {
  readonly type = FILTER_CHANGE;

  constructor(public payload: TimelineFilter, public updateTimeline = true, public updateFilter = true, public timelineBarSelected = false) {}
}

export class FilterUpdate implements Action {
  readonly type = FILTER_UPDATE;

  constructor(public payload: TimelineFilter, public updateFilter = true, public timelineBarSelected = false) {}
}
