import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as moment from "moment";
import { Observable } from "rxjs";
import { State } from "src/app/reducers";
import {
  AnalyticsTimeline,
  Category,
  Granularity,
  TimelineFilter
} from "src/app/shared/models/shared.model";
import { FilterChange } from "../../state/dashboard.actions";
import { isTimelineBarSelected, selectFilter, selectTimeline } from "../../state/dashboard.reducer";
import { staticSummaryDataImport } from "../dashboard/summaryStatic";

@Component({
  selector: "app-analytics-timeline",
  templateUrl: "./analytics-timeline.component.html",
  styleUrls: ["./analytics-timeline.component.scss"]
})
export class AnalyticsTimelineComponent implements OnInit {
  constructor(private store: Store<State>) { }

  MAX_DISPLAY_NUMBER = 110;

  timeline$: Observable<AnalyticsTimeline>;
  filter$: Observable<TimelineFilter>;
  timelineBarSelected: boolean;

  data: number[] = [];
  axis: moment.Moment[];
  granularity: Granularity;

  // graph colors
  color: string;

  selectedBarFilter: TimelineFilter;
  calendarFilter: TimelineFilter = {
    category: Category.ACTIVE,
    "date-from": moment()
      .subtract(1, "months")
      .toISOString(),
    "date-to": moment().toISOString(),
    granularity: Granularity.WEEK
  };

  tooMuchData = false;
  noData = false;

  ngOnInit() {
    this.timeline$ = this.store.select(selectTimeline);
    this.filter$ = this.store.select(selectFilter);

    this.filter$.subscribe(filter => {
      this.calendarFilter = filter;
    });

    this.store.select(isTimelineBarSelected)
    .subscribe(timelineBarSelected => {
      this.timelineBarSelected = timelineBarSelected;
    });

    this.timeline$.subscribe(timeline => {
      if (timeline) {
        this.noData = timeline.items.length === 0;
        this.tooMuchData = timeline.items.length > this.MAX_DISPLAY_NUMBER;

        const toFilterItems = timeline.items.slice(0, this.MAX_DISPLAY_NUMBER);

        this.data = toFilterItems.map(item => item.amount);
        this.axis = toFilterItems.map(item => moment(item["date-from"]));
        this.granularity = timeline.granularity;

        this.color = staticSummaryDataImport[this.calendarFilter.category].barColor;
      }
    });
  }

  timeSelected(time) {
    var value;

    if (this.calendarFilter.granularity == Granularity.WEEK) {
      value = "isoWeek";
    } else {
      value = this.calendarFilter.granularity;
    }

    let timeStart: moment.Moment | string = moment(time).startOf(value);
    let timeEnd: moment.Moment | string = moment(time).endOf(value);

    if (timeStart < this.calendarFilter["date-from"]) {
      timeStart = this.calendarFilter["date-from"];
    }

    if (timeStart > this.calendarFilter["date-to"]) {
      timeEnd = this.calendarFilter["date-to"];
    }

    const newFilter: TimelineFilter = {
      ...this.calendarFilter,
      "date-from": timeStart,
      "date-to": timeEnd
    };

    if (this.selectedBarFilter
      && newFilter["date-from"].toString() == this.selectedBarFilter["date-from"].toString()
      && newFilter["date-to"].toString() == this.selectedBarFilter["date-to"].toString()) {
      this.selectedBarFilter = undefined;
      this.store.dispatch(new FilterChange(this.calendarFilter, false, false, false));
    } else {
      this.selectedBarFilter = Object.assign({}, newFilter);
      this.store.dispatch(new FilterChange(newFilter, false, false, true));
    }
  }

  getTitleBoxClass() {
    if (this.calendarFilter && this.calendarFilter.category) {
      return this.calendarFilter.category + '-box';
    }
    return '';
  }
}
