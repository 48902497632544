import { ErrorHandler, Injectable } from '@angular/core';
import { NotifierService } from './notifier.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(protected notifierService: NotifierService) {}

  handleError(error: any) {
    console.error(error);
    this.notifierService.notifyError(error);
  }
}
