import * as moment from 'moment';
import { Granularity } from '../../models/shared.model';

export function buildConfig(data, granularity) {
  const ticks: any = {
    padding: 10
  };

  if (data.labels.length < 100) {
    ticks.source = 'data';
    ticks.autoSkip = true;
  }

  if (data.labels.length < 25) {
    ticks.autoSkip = false;
  }

  return {
    type: 'bar',
    data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      barRoundness: 0.4,
      scales: {
        xAxes: [
          {
            offset: true,
            type: 'time',
            display: true,
            gridLines: {
              color: '#F7F7F7'
            },
            time: {
              unit: granularity,
              displayFormats: {
                day: 'MMM D',
                week: 'll',
                month: 'MMM YYYY'
              }
            },
            ticks,
            barPercentage: 1.0
          }
        ],
        yAxes: [
          {
            gridLines: {
              color: '#F7F7F7'
            },
            ticks: {
              beginAtZero: true
            }
          }
        ]
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 45,
          bottom: 0
        }
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        yAlign: 'bottom',
        xAlign: 'center',
        callbacks: {
          label(tooltipItems) {
            return tooltipItems.value;
          },
          title(tooltipItem) {
            const currentDate = new Date(tooltipItem[0].label);
            const date = moment(currentDate);
            let format = 'll';

            if (granularity === Granularity.MONTH) {
              format = 'MMM YYYY';
            }

            return date.format(format);
          }
        }
      },
      plugins: {
        labels: {
          render(args) {
            return args.dataset.data.length < 20 ? args.value : '';
          },
          fontColor: 'white',
          textMargin: -25
        }
      },
      responsiveAnimationDuration: 1000
    }
  };
}
