import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import {
  selectIsDataLoaded
} from '../pages/dashboard/state/dashboard.reducer';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: "root"
})
export class NotifierService {
  currentSnackBar: MatSnackBarRef<SimpleSnackBar> = null;
  unhandledErrors: any[] = [];
  currentMessage: "";
  updateSubject$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  isNotLoading = false;

  constructor(
    protected snackBar: MatSnackBar,
    protected translate: TranslateService,
    protected router: Router,
    private store: Store<any>

  ) {
    this.store.select(selectIsDataLoaded).subscribe((newValue) => {
      this.isNotLoading = newValue;
    });
  }

  updateMessage(message: string) {
    this.updateSubject$.next(message);
    this.router.navigate(["error"], { queryParams : {error: encodeURIComponent(message)}});
  }

  showSnackBar(error) {
    this.currentMessage = error;
    // Open the error snackbar
    this.currentSnackBar = this.snackBar.open(
      error.toString(),
      this.translate.instant("errors.dismiss"),
      {
        panelClass: ["notification-error"],
        verticalPosition: "top"
      }
    );

    // After error dismissed, show the following error in the queue
    this.currentSnackBar.afterDismissed().subscribe(() => {
      this.currentSnackBar = null;
      if (this.unhandledErrors.length > 0) {
        this.notifyError(this.unhandledErrors.shift());
      } else {
        this.currentMessage = "";
      }
    });
  }

  notifyError(error: any, critical = false) {
    if (error.message) {
      error = error.message;
    }

    if (critical || !this.isNotLoading) {
      this.updateMessage(error);
    } else if (!this.currentSnackBar) {
      this.showSnackBar(error);
    } else if (
      !this.unhandledErrors.find(item => item === error) &&
      this.currentMessage !== error
    ) {
      // An error is currently showing, push the error to the queue
      this.unhandledErrors.push(error);
    }
  }

  clearErrorQueue() {
    this.unhandledErrors = [];
  }
}
