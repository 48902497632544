<form
  [formGroup]="filterGroup"
  fxLayout="row"
  fxFlex="grow"
  fxLayoutAlign="space-between center"
>
  <div fxLayoutGap="8px" fxLayout="row" fxLayoutGap="30px">
    <mat-form-field class="small-field" (click)="picker.open()" [matTooltip]=  "'common.filter.maxRange'|translate">
      <mat-label>{{ 'common.filter.dateRange' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input #dateRangeStart
          matStartDate
          [max]="filterGroup.value['date-to']"
          [placeholder]="'common.filter.date-from' | translate"
          formControlName="date-from"
          readonly
          class="date-field"
          (keydown)="(false)"
          [max]="today"

        />
        <input #dateRangeEnd
          matEndDate
          [min]="filterGroup.value['date-from']"
          [placeholder]="'common.filter.date-to' | translate"
          formControlName="date-to"
          readonly
          class="date-field"
          (keydown)="(false)"
          [max]="today"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker maxRange=30 (closed)="emitEvent()" #picker></mat-date-range-picker>
    </mat-form-field>
  </div>

  <div>
    <mat-button-toggle-group class="mat-elevation-z1" appearance="legacy" formControlName="granularity">
      <mat-button-toggle value="day" (click)="emitEvent()">
        {{ 'common.filter.day' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="week" (click)="emitEvent()">
        {{ 'common.filter.week' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="month" (click)="emitEvent()">
        {{ 'common.filter.month' | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</form>
