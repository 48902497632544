import { Component, OnInit } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { StyleService } from "src/app/shared/services/style.service";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"]
})
export class ErrorComponent implements OnInit {
  errorMessage: string;

  constructor(
    public keycloakService: KeycloakService,
    private location: Location,
    private route: ActivatedRoute,
    public styleService: StyleService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(paramMap => {
      this.location.replaceState("");
      this.errorMessage = decodeURIComponent(paramMap["error"]);
    });
  }
}
