import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Application, IKeycloakProfile } from '../models/shared.model';
import { Endpoint } from '../services/backend.service';
import { RouterState } from './../../reducers/router';
import { LoadDataAction, successAction } from './load.actions';
import { SAVE_USER_INFO, SaveUserInfo } from './shared.actions';

export interface State {
  applications: Application[];
  user: IKeycloakProfile;
}

export const initialState: State = {
  applications: [],
  user: { username: '' }
};

export function reducer(
  state = initialState,
  action: LoadDataAction | SaveUserInfo
): State {
  let stateChanges: any;
  switch (action.type) {
    case successAction(Endpoint.GET_SERVICE_PROVIDER_APPLICATIONS).type:

      stateChanges = {
        applications: (action.payload as Application[])
      };

      return {
        ...state,
        ...stateChanges
      };

    case SAVE_USER_INFO:

      stateChanges = {
        user: (action.payload as IKeycloakProfile)
      };

      return {
        ...state,
        ...stateChanges
      };

    default:
      return state;
  }
}

// Public state helpers

export const selectApplication = (sharedState: State, routerState: RouterState) => {
  if (!routerState.state.params.idApplication) { return undefined; }
  const selected = sharedState.applications.find(
    i => i.id.toLocaleLowerCase() === routerState.state.params.idApplication.toLocaleLowerCase());

  return selected as Application;
};

// Selectors

export const selectShared = createFeatureSelector<State>('shared');
export const selectRouter = createFeatureSelector<RouterState>('router');

export const selectApplications = createSelector(
  selectShared,
  (sharedState) => {
    return sharedState.applications;
  }
);

export const selectUser = createSelector(
  selectShared,
  (sharedState) => {
    return sharedState.user;
  }
);

export const selectApplicationServiceProvider = createSelector(
  selectShared,
  selectRouter,
  (sharedState, routerState) => selectApplication(sharedState, routerState)
);


