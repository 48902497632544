import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';
import { AnalyticsFilter } from '../../models/shared.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit {
  @Input() initialValues = {
    'date-from': new Date(),
    'date-to': new Date(),
    granularity: ''
  };

  @Input() tooMuchData = false;

  @Output()
  filterChanged = new EventEmitter<AnalyticsFilter>();

  filterGroup: FormGroup;

  today = moment();
  analyticsFilter: AnalyticsFilter;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.filterGroup = this.formBuilder.group({
      'date-from': this.initialValues['date-from'],
      'date-to': this.initialValues['date-to'],
      granularity: this.initialValues.granularity
    });

    this.filterGroup.controls.granularity.setValue(
      this.initialValues.granularity,
      { onlySelf: true }
    );

    this.filterGroup.valueChanges
      .pipe(
        distinctUntilChanged(
          (prev: AnalyticsFilter, current: AnalyticsFilter) =>
            _.isEqual(prev, current)
        )
      )
      .subscribe(value => this.analyticsFilter = value);
  }

  emitEvent() {
    if (this.analyticsFilter['date-from'] !== null && this.analyticsFilter['date-to'] !== null && this.analyticsFilter.category !== null) {
      this.filterChanged.emit(this.analyticsFilter);
    }
  }
}
