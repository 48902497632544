import { Component, Input, OnChanges } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-gravatar',
  templateUrl: './gravatar.component.html',
  styleUrls: ['./gravatar.component.scss']
})
export class GravatarComponent implements OnChanges {
  constructor() {}
  @Input() size = 40;
  @Input() email = '';
  @Input() class = 'avatar';

  url: string;

  md5 = new Md5();

  ngOnChanges() {
    if (this.email) {
      this.url =
        'https://www.gravatar.com/avatar/' +
        this.md5.appendStr(this.email).end() +
        '?s=' +
        this.size +
        '&d=identicon';
    } else {
      this.url = '/assets/img/noavatar.png';
    }
  }
}
