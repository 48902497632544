import { ElementRef } from '@angular/core';

export function buildConfig(canvas: ElementRef, type, data, segment, outputs) {
  return {
    type,
    data,
    options: {
      responsive: false,
      legend: {
        display: true,
        position: 'left',
        labels: {
          boxWidth: 20
        }
      },
      tooltips: {
        enabled: true
      },
      plugins: {
        labels: {
          fontColor: 'transparent'
        }
      },
      responsiveAnimationDuration: 1000,
      onClick: (evt, item) => {
        if (item.length > 0 && outputs) {
          segment.emit(outputs[item[0]._index]);
        }
      },
      onHover: (evt, item) => {
        if (item.length > 0) {
          canvas.nativeElement.style.cursor = 'pointer';
        } else {
          canvas.nativeElement.style.cursor = 'default';
        }
      }
    }
  };
}
