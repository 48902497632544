import {
  ActionReducerMap, MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromHome from '../home/state/home.reducer';
import * as fromDashboard from '../pages/dashboard/state/dashboard.reducer';
import * as fromShared from '../shared/state/shared.reducer';
import * as fromRouter from './router';

export interface State {
  shared: fromShared.State;
  dashboard: fromDashboard.DashboardState;
  home: fromHome.HomeState;
  router: fromRouter.RouterState;
}

export const reducers: ActionReducerMap<State> = {
  shared: fromShared.reducer,
  dashboard: fromDashboard.reducer,
  home: fromHome.reducer,
  router: fromRouter.reducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
