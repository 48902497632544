<mat-card
class="card state-widget"
[style.background]="backgroundColor"
[style.color]="textColor"
[class.selected]="selected"
[class.state-clicable]="clicable"
>
  <div fxLayout="row" fxLayoutAlign="space-between" class="value">
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="5px">
      <div
        [vrCountUp]="{ prefix: valuePrefix }"
        [startVal]="0"
        [endVal]="value"
      >
        {{ value }}
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="space-between"
        gdAlignColumns="center stretch"
      >
        <div
          class="change"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxFlexAlign="end"
        >
          <mat-icon *ngIf="changePercent" class="change-icon">{{ changeIcon }}</mat-icon>
          <div *ngIf="changePercent"
            class="percent"
            [vrCountUp]="{ suffix: '%' }"
            [startVal]="0"
            [endVal]="changePercent"
          >
            {{ changePercent }} %
          </div>
        </div>
        <div class="small-text" [class.padding-top]="!subChangePercentText || !changePercent">
          {{ subChangePercentText | translate }}
        </div>
      </div>
    </div>
    <img class="myicon" alt="icon" src="{{ icon }}" />
  </div>
  <div class="property">{{ property }}</div>
</mat-card>
