import { Directive, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src'
  }
})
export class DefaultImageDirective {
  @Input() src: any;
  @Input() default: string;

  constructor(public DomSanitizationService: DomSanitizer ) {
  }

  updateUrl() {
    this.src = this.DomSanitizationService.bypassSecurityTrustResourceUrl(this.default);
  }
}
