<mat-card>
  <div class="piechart-widget card space">
    <div
      class="header {{titleClasses}}"
      fxLayout="column"
      fxLayout.lt-md="row"
      fxLayoutAlign="space-between start"
    >
      <div class="name" fxLayout="row">
        <h3>{{ title | translate}}</h3>
      </div>
    </div>

    <div
      class="content"
      fxLayout="row wrap"
      fxLayoutAlign="center space-between center"
      fxLayoutGap="24px grid"
    >
      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="center start"
      >
        <h4>
          {{ subtitle | translate}}
        </h4>
        <h1>
          {{ total }}
        </h1>
        <div class="nexttitle-container" fxLayout="row wrap" fxLayoutGap="8px">
          <span class="nexttitle">
            {{ nexttitle | translate}}
          </span>
          <mat-icon *ngIf="changePercent" class="change-icon">{{ changeIcon }}</mat-icon>
          <span class="changePercent">
            {{ changePercent }}
          </span>

        </div>


        <app-elastic-horizontalbar
          [data]="dataHorizontal"
          [colors]="colorsHorizontal"
          [labels]="axisHorizontal"
          [numberOfSegments]="numberOfSegments"
        ></app-elastic-horizontalbar>
      </div>
      <div fxFlex="strech" fxFlex="0 0">
        <canvas width="280" height="170" #canvas></canvas>
      </div>
    </div>
  </div>
</mat-card>
