<div class="attacks-filter">
  <button *ngIf="exportOptions" mat-stroked-button [matMenuTriggerFor]="menu">{{ 'common.export.menu-button' | translate }}</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let exportOption of exportOptions" (click)="exportOption.callback()">{{ exportOption.text | translate }}</button>
  </mat-menu>
  <mat-button-toggle-group [(ngModel)]="filter.id" (change)="change($event.value)">
    <mat-button-toggle value="1h">1h</mat-button-toggle>
    <mat-button-toggle value="3h">3h</mat-button-toggle>
    <mat-button-toggle value="12h">12h</mat-button-toggle>
    <mat-button-toggle value="1d">1d</mat-button-toggle>
    <mat-button-toggle value="1w">1w</mat-button-toggle>
    <mat-button-toggle id="custom" value="custom" (click)="openCustomTimeRangeDialog($event)">
      <span *ngIf="customFiltering===0">
        {{(prevFilterValues['fromDate'] | date: 'd/M/yyyy') + ' - ' + (prevFilterValues['toDate'] | date: 'd/M/yyyy')}}
      </span>
      <span *ngIf="customFiltering===1">
        {{(prevFilterValues['timeValue']) + ' ' + (prevFilterValues['timeUnit'])}}
      </span>
      <span *ngIf="customFiltering===null">Custom</span>
      <mat-icon style="margin-left: 0.5rem;">calendar_month</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>