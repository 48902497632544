import { Component, Input, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'elastic-state-widget',
  templateUrl: './state-widget.component.html',
  styleUrls: ['./state-widget.component.scss']
})
export class StateWidgetComponent implements OnInit {

  @Input() backgroundColor = '#FFF';
  @Input() textColor = '#111';
  @Input() value: string;
  @Input() valuePrefix = '';
  @Input() property: string;
  @Input() icon: string;
  @Input() changePercent: string;
  @Input() changeIcon: string;
  @Input() selected: boolean;
  @Input() subChangePercentText: string;
  @Input() clicable: boolean;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.elementRef.nativeElement.style.setProperty('--color', this.backgroundColor);
  }

}
