<div fxLayout="row" fxLayoutWrap fxLayoutGap="26px">
  <div class="widget" fxFlex="1 0">
    <app-elastic-piechart-widget
      *ngIf="details$ | async as detail"
      [title]="'dashboard.details.devices'"
      [titleClasses]="getDetailsBoxClasses()"
      [subtitle]="'dashboard.details.totalEndpoints'"
      [nexttitle]="'dashboard.details.escalatedDevices'"
      [colors]="[iosStaticInfo.baseColor, androidStaticInfo.baseColor]"
      [eventOutput]="[DeviceOs.IOS, DeviceOs.ANDROID]"
      [axis]="[
        'dashboard.details.ios' | translate,
        'dashboard.details.android' | translate
      ]"
      [data]="[
        getTotalInfo(detail).iosAmount,
        getTotalInfo(detail).androidAmount
      ]"
      [type]="'pie'"
      [axisHorizontal]="['dashboard.details.ios', 'dashboard.details.android']"
      [dataHorizontal]="[
        getTotalInfo(detail).iosRooted,
        getTotalInfo(detail).androidRooted
      ]"
      [colorsHorizontal]="[
        iosStaticInfo.baseColor,
        androidStaticInfo.baseColor
      ]"
      [numberOfSegments]="30"
      (segment)="selectedSegment($event)"
    ></app-elastic-piechart-widget>
  </div>


  <div class="widget" fxFlex="1 0">
    <app-elastic-piechart-widget
      *ngIf="details$ | async as detail"
      [title]="selectedStaticInfo().title"
      [titleClasses]="getDetailsBoxClasses()"
      [subtitle]="'dashboard.details.totalEndpoints'"
      [nexttitle]="selectedStaticInfo().rooted"
      [colors]="selectedStaticInfo().colors"
      [axis]="selectedInfo(detail).versions"
      [data]="selectedInfo(detail).values"
      [type]="'doughnut'"
      [axisHorizontal]="[
        selectedStaticInfo().rooted,
        selectedStaticInfo().nonRooted
      ]"
      [dataHorizontal]="[
        selectedInfo(detail).rootedDevices,
        selectedInfo(detail).devices -
          selectedInfo(detail).rootedDevices
      ]"
      [colorsHorizontal]="[
        selectedStaticInfo().baseColor,
        selectedStaticInfo().lightColor
      ]"
      [numberOfSegments]="20"
      [changePercent]="getTrend(selectedInfo)"
      [changeIcon]="selectedInfo(detail).changeIcon"
    ></app-elastic-piechart-widget>
  </div>
</div>
