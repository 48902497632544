import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromDashboard from './state/dashboard.reducer';
import { routes } from './dashboard.routes';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AnalyticsDetailsComponent } from './components/analytics-details/analytics-details.component';
import { AnalyticsTimelineComponent } from './components/analytics-timeline/analytics-timeline.component';
import { DashboardEffects } from './state/dashboard.effect';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [DashboardComponent, AnalyticsDetailsComponent, AnalyticsTimelineComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('dashboard', fromDashboard.reducer),
    EffectsModule.forFeature([DashboardEffects]),
    SharedModule
  ]
})
export class DashboardModule {}
