import {
  animate, state,
  style, transition, trigger
} from '@angular/animations';
import {
  AfterViewInit, ChangeDetectorRef, Component,
  Input, OnChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-elastic-horizontalbar',
  templateUrl: './horizontalbar.component.html',
  styleUrls: ['./horizontalbar.component.scss'],
  animations: [
    trigger('myAnimation', [
      state(
        'none',
        style({
          opacity: 0
        })
      ),
      state(
        'max',
        style({
          opacity: 1
        })
      ),
      transition('none => max', [animate('1s')])
    ])
  ]
})
export class HorizontalbarComponent implements OnChanges, AfterViewInit {
  @Input() data: number[];
  @Input() colors: string[];
  @Input() labels: string[] = [];
  @Input() numberOfSegments: number;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  total = 0;
  segments: string[] = [];
  width;
  smallwidth;
  tooltip: string;
  state = 'none';

  ngOnChanges() {
    if (this.data.length === 0) {
      return;
    }

    this.segments = [];

    this.total = this.data.reduce((a, b) => a + b);
    this.width = 200 / this.numberOfSegments + 'px';
    this.smallwidth = 100 / this.numberOfSegments + 'px';

    for (let i = 0; i < this.data.length; i++) {
      const limit = Math.round(
        (this.numberOfSegments * this.data[i]) / this.total
      );
      for (let j = 0; j < limit; j++) {
        this.segments.push(this.colors[i]);
      }
    }

    this.tooltip = this.generateTooltip();
  }

  ngAfterViewInit() {
    this.state = 'max';
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }

  generateTooltip(): string {
    let tooltip = '';
    if (this.labels.length === this.data.length) {
      let first = true;

      for (let i = 0; i < this.data.length; i++) {
        if (!first) {
          tooltip += '; ';
        }
        tooltip += this.translate.instant(this.labels[i]) + ': ' + this.data[i];
        first = false;
      }
    }
    return tooltip;
  }

  getColorTooltip(color: string) {
    const index = this.colors.findIndex(element => element === color);
    return this.data[index];
  }
}
