import { Action } from '@ngrx/store';
import { loadAction } from './load.actions';
import { Endpoint } from '../services/backend.service';
import { KeycloakProfile } from 'keycloak-js';

export const REDIRECT = '[Shared] Select Service Provider';
export const GET_USER_INFO = '[Shared] Get user info from keycloak';
export const SAVE_USER_INFO = '[Shared] Save user data';
export const APP_INIT = '[Shared] App initializer';

export function loadServiceProviders() {
  return loadAction(Endpoint.GET_SERVICE_PROVIDER_APPLICATIONS, {});
}

export class Redirect implements Action {
  readonly type = REDIRECT;

  constructor(
    public payload: {
      url: string;
    }
  ) {}
}

export class AppInit implements Action {
  readonly type = APP_INIT;

  constructor() {}
}

export class GetUserInfo implements Action {
  readonly type = GET_USER_INFO;
  constructor() {}
}

export class SaveUserInfo implements Action {
  readonly type = SAVE_USER_INFO;
  constructor(public payload: KeycloakProfile) {}
}

export type SharedAction = Redirect;
