import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AttacksFilter } from '../../models/attacks-filter.model';
import { Granularity } from '../../models/shared.model';
import { CustomTimeRangeDialogComponent } from './custom-time-range-dialog/custom-time-range-dialog.component';

@Component({
  selector: 'app-attacks-filter',
  templateUrl: './attacks-filter.component.html',
  styleUrls: ['./attacks-filter.component.scss']
})
export class AttacksFilterComponent {
  @Input() filter: AttacksFilter;
  @Input() exportOptions: any;
  @Output() changeFilter = new EventEmitter<AttacksFilter>();

  applicationId: string;
  prevId = '12h';
  prevFilterValues = {};
  customFiltering = null;

  constructor(private dialog: MatDialog, private route: ActivatedRoute) {
    this.applicationId = this.route.snapshot.parent.parent.params.idApplication;
  }

  change(value: string): void {
    if (value !== 'custom') {
      this.customFiltering = null;
      const newFilter = this.calculateTimeSelected(value);
      this.filter = newFilter;
      this.prevId = value;
      this.changeFilter.emit(this.filter);
    }
  }

  openCustomTimeRangeDialog(evt: MouseEvent): void {
    const target = new ElementRef(evt.currentTarget);
    const dialogRef = this.dialog.open(CustomTimeRangeDialogComponent, {
      data: { trigger: target, prevFilterValues: this.prevFilterValues }
    });
    dialogRef.afterClosed().subscribe((res: { fromDate: Date, toDate: Date, formValues: any }) => {
      if (res) {
        this.prevFilterValues = res.formValues;
        this.customFiltering = res.formValues.selectedTab;
        this.prevId = 'custom';
        const daysDiff = this.daysBetween(res.fromDate.toString(), res.toDate.toString());
        const granularity = daysDiff >= 1 ? Granularity.DAY : Granularity.HOUR;

        const newFilter: AttacksFilter = {
          id: 'custom',
          fromDate: res.fromDate.toString(),
          toDate: res.toDate.toString(),
          granularity,
          'application-id': this.applicationId,
        };
        this.filter = newFilter;
        this.changeFilter.emit(this.filter);
      } else {
        this.customFiltering = null;
        this.filter.id = this.prevId;
      }
    });
  }

  private daysBetween(date1: string, date2: string) {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.floor(Math.abs((new Date(date1).getTime() - new Date(date2).getTime()) / oneDay));
  }

  private calculateTimeSelected(value: string): AttacksFilter {
    const filter: AttacksFilter = {
      id: value,
      fromDate: '',
      toDate: new Date().toString(),
      granularity: Granularity.HOUR,
      'application-id': this.applicationId,
    };

    switch (value) {
      case '1h':
        filter.fromDate = new Date(new Date().setHours(new Date().getHours() - 1)).toString();
        break;
      case '3h':
        filter.fromDate = new Date(new Date().setHours(new Date().getHours() - 3)).toString();
        break;
      case '12h':
        filter.fromDate = new Date(new Date().setHours(new Date().getHours() - 12)).toString();
        break;
      case '1d':
        filter.fromDate = new Date(new Date().setDate(new Date().getDate() - 1)).toString();
        break;
      case '1w':
        filter.fromDate = new Date(new Date().setDate(new Date().getDate() - 7)).toString();
        break;
      default:
        break;
    }

    const daysDiff = this.daysBetween(filter.fromDate, filter.toDate);
    filter.granularity = daysDiff >= 1 ? Granularity.DAY : Granularity.HOUR;

    return filter;
  }
}
