import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside.directive';
import { CountUpDirective } from './count-up.directive';
import { HighlightDirective } from './highlight.directive';
import { DefaultImageDirective } from './defaultImg.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ClickOutsideDirective,
    CountUpDirective,
    HighlightDirective,
    DefaultImageDirective
  ],
  exports: [
    ClickOutsideDirective,
    CountUpDirective,
    HighlightDirective,
    DefaultImageDirective
  ]
})
export class UtilsModule { }
